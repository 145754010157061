export const ServiceUrl = {
  DASHBOARD: {
    FIRST_LINE_COUNT: "v1/admin/dashboard/first-line-counts",
    STUDENTS_BIRTHDAY_LIST: "v1/admin/dashboard/students-birthday-list",
    STAFFS_BIRTHDAY_LIST: "v1/admin/dashboard/staffs-birthday-list",

    STUDENTS_COUNT: "v1/admin/dashboard/students-count",
    TODAY_DAY_ORDER: "v1/admin/dashboard/today-day-order",
  },
  ACADEMIC: {
    LIST_ACADEMIC_YEARS: "v1/admin/academic_years/list-academic-years",
    LIST_COURSES: "v1/admin/courses/list-courses",
    LIST_COURSES_V2: "v1/admin/courses/list-courses", // New Course List based on v2 Department
    REMOVE_COURSE_FROM_DEPT: "v1/admin/courses/remove-course-from-dept", // New Course List based on v2 Department

    SAVE_COURSES: "v1/admin/courses/save-course",
    UPDATE_COURSES: "v1/admin/courses/update-course",
    REMOVE_COURSES: "v1/admin/courses/remove-course",
  },
  ADMISSION: {
    SETTINGS: "v1/settings",
    LIST_VARIABLE_SETTINGS: "v1/admin/settings/list-settings",
    UPDATE_VARIABLE_SETTINGS: "v1/admin/settings/update-settings",
    ADD_VARIABLE_SETTINGS: "v1/admin/settings/add-settings",

    LIST_SMS_TEMPLATES: "v1/admin/smstemplate/list-templates",
    UPDATE_SMS_TEMPLATES: "v1/admin/smstemplate/update-template",
    ADD_SMS_TEMPLATES: "v1/admin/smstemplate/add-template",
    DELETE_SMS_TEMPLATES: "v1/admin/smstemplate/delete-template",

    LIST_MAIL_TEMPLATES: "v1/admin/mailtemplate/list-templates",
    UPDATE_MAIL_TEMPLATES: "v1/admin/mailtemplate/update-template",
    ADD_MAIL_TEMPLATES: "v1/admin/mailtemplate/add-template",
    DELETE_MAIL_TEMPLATES: "v1/admin/mailtemplate/delete-template",

    LIST_PAGES: "v1/admin/webpage/list-pages",
    UPDATE_PAGE: "v1/admin/webpage/update-page",
    VIEW_PAGE: "v1/admin/webpage/view-page",
    DELETE_PAGE: "v1/admin/webpage/delete-page",
    ADD_PAGE: "v1/admin/webpage/add-page",

    USER_PROFILE: "v1/admin/users/my-profile",
    USER_CHANGE_PASSWORD: "v1/admin/users/change-password",

    NEW_USER_LOGIN: "v1/admin/users/save-user",
    LIST_USERS_LOGIN: "v1/admin/users/list-users",
    USER_STATUS_CHANGE: "v1/admin/users/status-change",
    REMOVE_USER_LOGIN: "v1/admin/users/remove-user",
    UPDATE_USER_LOGIN: "v1/admin/users/update-user",
    PASS_USER_LOGIN: "v1/admin/users/user-password",
  },
  STUDENTS: {
    SEARCH_BY_REGISTER_NO: "v1/admin/student_search/search-student-by-no",
    SEARCH_BY_NAME: "v1/admin/student_search/search-by-name",

    SAVE_BUS_ROUTE: "v1/admin/student/save-vehicle",
    TRANSPORT_DETAILS: "v1/admin/student_search/transport-details",
    PROMOTION_DETAILS: "v1/admin/student_search/promotion-details",
    SAVE_PROMOTION: "v1/admin/student/save-promotion",
    REMOVE_PROMOTION: "v1/admin/student/remove-promotion",
    UPDATE_BULK_FIELD: "v1/admin/student/update-bulk-field",

    SAVE_STUDENT: "v1/admin/student/save-student",
    UPDATE_STUDENT: "v1/admin/student/update-student",

    STUDENT_STRENGTH: "v1/admin/student_report/current-strength",

    IMPORT_STUDENT: "v1/admin/importstudent/save",

    SAVE_BONAFIED_CERTIFICATE: "v1/admin/student/bonaifed/save",
    LIST_BONAFIED_CERTIFICATES: "v1/admin/student/bonaifed/list-all",
    DELETE_BONAFIED_CERTIFICATE: "v1/admin/student/bonaifed/delete",
    SAVE_CONDUCT_CERTIFICATE: "v1/admin/student/conduct/save",
    LIST_CONDUCT_CERTIFICATES: "v1/admin/student/conduct/list-all",
    DELETE_CONDUCT_CERTIFICATE: "v1/admin/student/conduct/delete",
    SAVE_MEDIUM_CERTIFICATE: "v1/admin/student/medium/save",
    LIST_MEDIUM_CERTIFICATES: "v1/admin/student/medium/list-all",
    DELETE_MEDIUM_CERTIFICATE: "v1/admin/student/medium/delete",
    LIST_VERIFY_CERTIFICATE: "v1/admin/student/verification/list",
    SAVE_VERIFY_CERTIFICATE: "v1/admin/student/verification/save",

    DATE_WISE_ATTENDANCE: "v1/admin/student/attendance/get",
    UPDATE_DAY_ATTENDANCE: "v1/admin/student/attendance/update",
    SAVE_LONG_ABSENT_STUDENT: "v1/admin/student/attendance/save-long-absent",
    LONG_ABSENT_STUDENTS: "v1/admin/student/attendance/long-absentee",
    GET_MARKED_GROUP_BY_DAYHOUR: "v1/admin/student/attendance/get-marked",
    DELETE_LONG_ABSENT_STUDENT:
      "v1/admin/student/attendance/delete-long-absentee",
    DAy_WISE_ENTRY_RECORDS: "v1/admin/student/attendance/get-day-records",
    DELETE_DAY_ENTRY: "v1/admin/student/attendance/delete-day-records",
    MARK_BULK_OD: "v1/admin/student/attendance/mark-bulkod",

    ALLOCATED_CLASS_WISE_SUBJECTS: "v1/admin/student/subject/allocated-list",
    ALLOCATE_CLASS_WISE_SUBJECTS: "v1/admin/student/subject/allocate",
    STUDENTS_SUBJECT_LIST: "v1/admin/student/subject/students-allocated-list",
    DELETE_STUDENT_SUBJECT: "v1/admin/student/subject/delete-student-subject",
    FIND_COURSE_SUBJECT: "v1/admin/student/subject/find",
    UPDATE_STUDENT_ALLOCATED_SUBJECT: "v1/admin/student/subject/change-subject",
    SAVE_STUDENT_ALLOCATED_SUBJECT: "v1/admin/student/subject/add-subject",
    DELETE_ALLOCATED_SUBJECTS: "v1/admin/student/subject/delete-allocated",

    REMOVE_LEFT: "v1/admin/student/remove-left",
    UPDATE_LEFT: "v1/admin/student/update-left",
  },
  SETTINGS: {
    SAVE_CASHBOOKS: "v1/admin/cashbooks/save",
    LIST_CASHBOOKS: "v1/admin/cashbooks/list",
    DELETE_CASHBOOK: "v1/admin/cashbooks/delete",
    UPDATE_CASHBOOK: "v1/admin/cashbooks/update",

    SAVE_GATEWAY_SUBACCOUNT: "v1/admin/gateway/sub/save",
    LIST_GATEWAY_SUBACCOUNT: "v1/admin/gateway/sub/list",
    DELETE_GATEWAY_SUBACCOUNT: "v1/admin/gateway/sub/delete",
    UPDATE_GATEWAY_SUBACCOUNT: "v1/admin/gateway/sub/update",

    SAVE_COLLEGE_YEAR: "v1/admin/collegeyear/save",
    LIST_COLLEGE_YEAR: "v1/admin/collegeyear/list",
    DELETE_COLLEGE_YEAR: "v1/admin/collegeyear/delete",
    UPDATE_COLLEGE_YEAR: "v1/admin/collegeyear/update",

    SAVE_USERROLE: "v1/admin/rbac/userrole/save",
    LIST_USERROLE: "v1/admin/rbac/userrole/list",
    DELETE_USERROLE: "v1/admin/rbac/userrole/delete",
    UPDATE_USERROLE: "v1/admin/rbac/userrole/update",

    SAVE_ROLE_MODULE: "v1/admin/rbac/roleaccess/save-module",
    DELETE_ROLE_MODULE: "v1/admin/rbac/roleaccess/delete-module",
    UPDATE_ROLE_MODULE: "v1/admin/rbac/roleaccess/update-module-access",
    UPDATE_USER_ACCESS: "v1/admin/rbac/roleaccess/update-user-access",
    LIST_ROLE_ACCESS: "v1/admin/rbac/roleaccess/access-list",
    UPDATE_ROLE_ACCESS: "v1/admin/rbac/roleaccess/update-role-access",
    LIST_ROLE_PERMISSIONS: "v1/admin/rbac/roleaccess/list-role-permissions",

    SAVE_BATCH: "v1/admin/settings/batch/save",
    LIST_BATCH: "v1/admin/settings/batch/list",
    DELETE_BATCH: "v1/admin/settings/batch/delete",
    UPDATE_BATCH: "v1/admin/settings/batch/update",
    UPDATE_SEMESTER_DATES: "v1/admin/settings/batch/update-semester-dates",
    SEMESTER_DATES: "v1/admin/settings/batch/semester-dates",
    COLLEGE_YEARS: "v1/admin/settings/batch/list-college-years",

    SAVE_DEPARTMENT: "v1/admin/settings/departments/save",
    LIST_DEPARTMENT: "v1/admin/settings/departments/list",
    DELETE_DEPARTMENT: "v1/admin/settings/departments/delete",
    UPDATE_DEPARTMENT: "v1/admin/settings/departments/update",
    UPDATE_DEPARTMENT_COURSE:
      "v1/admin/settings/departments/update-dept-course",

    SAVE_SUBJECT_NATURE: "v1/admin/settings/subjectnature/save",
    LIST_SUBJECT_NATURE: "v1/admin/settings/subjectnature/list",
    REMOVE_SUBJECT_NATURE: "v1/admin/settings/subjectnature/delete",
    UPDATE_SUBJECT_NATURE: "v1/admin/settings/subjectnature/update",

    SAVE_SUBJECT: "v1/admin/settings/subjects/save",
    LIST_SUBJECT: "v1/admin/settings/subjects/list",
    DELETE_SUBJECT: "v1/admin/settings/subjects/delete",
    UPDATE_SUBJECT: "v1/admin/settings/subjects/update",

    SAVE_COURSE_SUBJECT: "v1/admin/settings/courseSubjects/save",
    LIST_COURSE_SUBJECT: "v1/admin/settings/courseSubjects/list",
    DELETE_COURSE_SUBJECT: "v1/admin/settings/courseSubjects/delete",
    UPDATE_COURSE_SUBJECT: "v1/admin/settings/courseSubjects/update",

    SAVE_STAFFSUBJECT: "v1/admin/settings/staffsubject/save",
    LIST_STAFFSUBJECT: "v1/admin/settings/staffsubject/list",
    DELETE_STAFFSUBJECT: "v1/admin/settings/staffsubject/delete",
    UPDATE_STAFFSUBJECT: "v1/admin/settings/staffsubject/update",

    SAVE_TIMETABLE: "v1/admin/settings/timetable/save-time-table",
    UPDATE_TIMETABLE: "v1/admin/settings/timetable/update-time-table",
    GET_TIMETABLE: "v1/admin/settings/timetable/get-timetable",
    REMOVE_TIMETABLE: "v1/admin/settings/timetable/remove",

    LIST_COMMUNITY: "v1/set/community",
    LIST_RELIGIONS: "v1/set/religions",

    LIST_USER_LOGS: "v1/admin/logs/list-all",
  },
  FEE_CATEGORY: {
    SAVE_CATEGORY: "v1/admin/fees/category/save",
    LIST_CATEGORY: "v1/admin/fees/category/list",
    DELETE_CATEGORY: "v1/admin/fees/category/delete",
    UPDATE_CATEGORY: "v1/admin/fees/category/update",

    SAVE_FEE_GROUP: "v1/admin/fees/group/save",
    LIST_FEE_GROUP: "v1/admin/fees/group/list",
    DELETE_FEE_GROUP: "v1/admin/fees/group/delete",
    UPDATE_FEE_GROUP: "v1/admin/fees/group/update",
    UPDATE_FEE_GROUP_CATEGORY_LIST: "v1/admin/fees/group/update-list-id",

    SAVE_PAYMENT_METHOD: "v1/admin/fees/payment_method/save",
    LIST_PAYMENT_METHOD: "v1/admin/fees/payment_method/list",
    DELETE_PAYMENT_METHOD: "v1/admin/fees/payment_method/delete",
    UPDATE_PAYMENT_METHOD: "v1/admin/fees/payment_method/update",

    CATEGORY_LIST_WITH_STUDENT_COUNT:
      "v1/admin/fees/assign/category-with-student-count",
    SAVE_COURSE_WISE_FEE: "v1/admin/fees/assign/save-course-wise-fee",
    SAVE_STUDENT_WISE_FEE: "v1/admin/fees/assign/save-student-wise-fee",

    SAVE_COURSE_WISE_FEE_ADJUST: "v1/admin/fees/assign/save-course-wise-adjust",
    SAVE_STUDENT_WISE_FEE_ADJUST:
      "v1/admin/fees/assign/save-student-wise-adjust",
  },
  FEES: {
    BILL_NO: "v1/admin/fees/payment/bill-no",
    LIST_PAYMENT: "v1/admin/fees/payment/student-bills",
    SAVE_STUDENT_PAYMENT: "v1/admin/fees/payment/save-student-payment",
    LIST_STUDENT_PAYMENT: "v1/admin/fees/payment/list-bills",
    VIEW_STUDENT_BILL: "v1/admin/fees/payment/get-bill",
    DELETE_ASSIGNED_FEE: "v1/admin/fees/payment/delete-fee-record",

    CHALLAN_BILL_NO: "v1/admin/fees/challan/bill-no",
    CHALLAN_LIST_PAYMENT: "v1/admin/fees/challan/student-bills",
    CHALLAN_SAVE_STUDENT_PAYMENT: "v1/admin/fees/challan/save-student-payment",
    CHALLAN_LIST_STUDENT_PAYMENT: "v1/admin/fees/challan/list-bills",
    CHALLAN_VIEW_STUDENT_BILL: "v1/admin/fees/challan/get-bill",
    CHALLAN_DELETE_ASSIGNED_FEE: "v1/admin/fees/challan/delete-fee-record",

    FEE_CATEGORY_REPORT: "v1/admin/fees/reports/category-reoprt",
    FEE_CATEGORY_SUMMARY: "v1/admin/fees/reports/category-summary",
    CLASS_WISE_PENDING: "v1/admin/fees/reports/class-wise-pending",
    CUSTOM_FEE_REPORT: "v1/admin/fees/reports/custom-fee-report",
    CUSTOM_FEE_CATEGORY_REPORT: "v1/admin/fees/reports/custom-category-reoprt",

    LIST_TEMPLATES: "v1/admin/fees/template/list-all",
    REMOVE_TEMPLATE: "v1/admin/fees/template/remove-data",
    SAVE_TEMPLATE: "v1/admin/fees/template/save-data",
    UPDATE_TEMPLATE: "v1/admin/fees/template/update-data",

    CANCEL_BILL: "v1/admin/fees/payment/cancel-bill",

    SAVE_BANK: "v1/admin/fees/bank/save",
    LIST_BANKS: "v1/admin/fees/bank/list",
    DELETE_BANK: "v1/admin/fees/bank/delete",
    UPDATE_BANK: "v1/admin/fees/bank/update",
  },
  TRANSPORT: {
    SAVE_VEHICLE: "v1/admin/vehicle/transport/save-data",
    LIST_VEHICLES: "v1/admin/vehicle/transport/list-data",
    REMOVE_VEHICLE: "v1/admin/vehicle/transport/remove-data",
    UPDATE_VEHICLE: "v1/admin/vehicle/transport/update-data",

    SAVE_DESTINATION: "v1/admin/vehicle/destination/save-data",
    LIST_DESTINATION: "v1/admin/vehicle/destination/list-data",
    REMOVE_DESTINATION: "v1/admin/vehicle/destination/remove-data",
    UPDATE_DESTINATION: "v1/admin/vehicle/destination/update-data",
  },
  HR: {
    LIST_PRODUCT: "v1/admin/hr.list_product",
    LIST_UNIT: "v1/admin/hr.list_unit",
    LIST_GROUP: "v1/admin/hr.list_group",
    SAVE_GRADE: "v1/admin/hr.save_grade",
    LIST_GRADES: "v1/admin/hr.list_gardes",
    REMOVE_GRADE: "v1/admin/hr.remove_grade",
    UPDATE_GRADE: "v1/admin/hr.update_grade",
    SAVE_BREAKUP_HEAD: "v1/admin/hr.save_breakuphead",
    LIST_BREAKUP_HEADS: "v1/admin/hr.list_breakuphead",
    REMOVE_BREAKUP_HEAD: "v1/admin/hr.remove_breakuphead",
    UPDATE_BREAKUP_HEAD: "v1/admin/hr.update_breakuphead",
    EMPLOYEE_NEW_CODE: "v1/admin/hr.employee_code",
    SAVE_EMPLOYEE: "v1/admin/hr.employee_save",
    LIST_EMPLOYEES: "v1/admin/hr.employees",
    REMOVE_EMPLOYEE: "v1/admin/hr.employee_remove",
    CHANGE_EMPLOYEE_STATUS: "v1/admin/hr.status_chagne",
    UPDATE_EMPLOYEE: "v1/admin/hr.employee_update",
    EMPLOYEE_BY_CODE: "v1/admin/hr.employee_by_code",
    SAVE_BIOMETRIC_DEVICE: "v1/admin/hr.device_save",
    LIST_BIOMETRIC_DEVICES: "v1/admin/hr.device_list",
    REMOVE_BIOMETRIC_DEVICE: "v1/admin/hr.device_remove",
    UPDATE_BIOMETRIC_DEVICE: "v1/admin/hr.device_update",
    SAVE_HOLIDAY: "v1/admin/hr.holiday_save",
    LIST_HOLIDAYS: "v1/admin/hr.holiday_list",
    REMOVE_HOLIDAY: "v1/admin/hr.holiday_remove",
    SAVE_SUNDAYS: "v1/admin/hr.save_sundays",
    ATT_MONTH_LOG: "v1/admin/hr.att_month_log",
    SAVE_BRANCH: "v1/admin/hr.branch/save-data",
    LIST_BRANCHES: "v1/admin/hr.branch/list-data",
    REMOVE_BRANCHE: "v1/admin/hr.branch/remove-data",
    UPDATE_BRANCHE: "v1/admin/hr.branch/update-data",
    SAVE_DEPARTMENT: "v1/admin/hr.department/save-data",
    LIST_DEPARTMENTS: "v1/admin/hr.department/list-data",
    LIST_STDEPARTMENTS: "v1/admin/student_dept/list-data",
    REMOVE_DEPARTMENT: "v1/admin/hr.department/remove-data",
    UPDATE_DEPARTMENT: "v1/admin/hr.department/update-data",
    SAVE_DESIGNATION: "v1/admin/hr.designation/save-data",
    LIST_DESIGNATIONS: "v1/admin/hr.designation/list-data",
    REMOVE_DESIGNATION: "v1/admin/hr.designation/remove-data",
    UPDATE_DESIGNATION: "v1/admin/hr.designation/update-data",
  },
  FIELD_SETTINGS: {
    NEW: "v1/field_settings/new",
    LIST: "v1/field_settings/all",
    REMOVE: "v1/field_settings/delete",
    UPDATE: "v1/field_settings/update",
  },
  SMS: {
    CHECK_BALANCE: "v1/admin/sms/balance",
  },
  WEBSITE_CMS: {
    SAVE_PAGE: "v1/admin/cms/page/save",
    LIST_PAGES: "v1/admin/cms/page/list",
    DELETE_PAGE: "v1/admin/cms/page/delete",
    GET_PAGE: "v1/admin/cms/page/get",
    UPDATE_PAGE: "v1/admin/cms/page/update",

    SAVE_GALLERY: "v1/admin/cms/page/save-gallery",
    LIST_GALLERIES: "v1/admin/cms/page/list-galleries",
    DELETE_GALLERY: "v1/admin/cms/page/delete-gallery",
    LIST_GALLERY: "v1/admin/cms/page/list-gallery",
    UPDATE_GALLERY: "v1/admin/cms/page/update-gallery",

    LIST_CATEGORY_NAMES: "v1/admin/cms/page/list-categories",

    FILES_LIST_DIRECTORY: "v1/admin/files/list-directory-v2",
    FILES_UPLOAD: "v1/admin/files/upload-files-v2",
    REMOVE_FILE: "v1/admin/files/remove-v2",
    CREATE_FOLDER: "v1/admin/files/create-folder-v2",
  },
  DEPT_CMS: {
    SAVE_SLIDER: "v1/admin/deptcms/slider/save",
    LIST_SLIDERS: "v1/admin/deptcms/slider/list",
    DELETE_SLIDER: "v1/admin/deptcms/slider/delete",

    SAVE_PAGE: "v1/admin/deptcms/page/save",
    GET_PAGE: "v1/admin/deptcms/page/get",

    SAVE_FILE: "v1/admin/deptcms/file/save",
    LIST_FILES: "v1/admin/deptcms/file/list",
    DELETE_FILE: "v1/admin/deptcms/file/remove",
    UPDATE_FILE: "v1/admin/deptcms/file/update",
  },

  UTILITIES: {
    LIST_STUDENT_CERTIFICATES: "v1/admin/util/stucert/list-all",
    GET_STUDENT_CERTIFICATE: "v1/admin/util/stucert/get",
    UPDATE_STUDENT_CERTIFICATE: "v1/admin/util/stucert/update",

    SAVE_DAY_ORDER: "v1/admin/util/attdayorder/save",
    LIST_DAY_ORDERS: "v1/admin/util/attdayorder/list",
    DELETE_DAY_ORDER: "v1/admin/util/attdayorder/delete",
    UPDATE_DAY_ORDER: "v1/admin/util/attdayorder/update",
    DAY_ORDER_BY_BATCH: "v1/admin/util/attdayorder/by-batch",
  },
  MOB: {
    CHECK_USERNAME: "mob/v1/login",
    VERIFY_LOGIN_OTP: "mob/v1/verify_otp",
    SET_LOGIN_PASSWORD: "mob/v1/set_password",
    MY_TIME_TABLE: "mob/v1/timetable/my-time-table",
    SEARCH_STUDENT: "mob/v1/students/search",
    SAVE_STUDENT_ATTENDANCE: "mob/v1/timetable/save",
    LIST_STUDENTS_ATTENDANCE: "mob/v1/timetable/list-attendance",
    CURRENT_SEM_DATE: "mob/v1/timetable/current-sem-date",
    LIST_LONG_ABSENTEE: "mob/v1/students/long-absentee",
  },
  PRODUCT: {
    ITEMGROUP: "v1/admin/product/itemgroup",
    TAXLIST: "v1/admin/product/listtax",
    BRANDLIST: "v1/admin/product/listbrand",
    ADDPRODUCT: "v1/admin/product/addproduct",
    LISTPRODUCT: "v1/admin/product/listproduct",
    EDITPRODUCT: "v1/admin/product/editproduct",
    DELETEPRODUCT: "v1/admin/product/deleteproduct?id=",
    LISTDEALER:'v1/admin/product/listdata',
  },
  PARTIES:{
      ADDPARTIES: "v1/admin/parties/addparties",
      LISTPARTIES: "v1/admin/parties/listparties",
      EDITPARTIES: "v1/admin/parties/editparties",
      DELETEPARTIES: "v1/admin/parties/deleteparties?id=",
  },

  ITEMGROUP:
  {
    ADDITEMGROUP: "v1/admin/itemgroup/additemgroup",
    LISTITEMGROUP: "v1/admin/itemgroup/listitemgroup",
    EDITITEMGROUP: "v1/admin/itemgroup/edititemgroup",
    DELETEITEMGROUP: "v1/admin/itemgroup/deleteitemgroup?id=",
  },
  DEALER:{
    ADDVEHICLE: "v1/admin/vehicle/addvehicle",
   // LISTDEALER: 'v1/admin/vehicle/listvehicle',
    EDITVEHICLE: "v1/admin/vehicle/editvehicle",
    DELETEVEHICLE: "v1/admin/vehicle/deletevehicle?id=",
    ITEMGROUP:"v1/admin/vehicle/listdealer",
    MODAL:"v1/admin/vehicle/listmodel",
    LISTDEALERS: 'v1/admin/vehicle/listvehicles',
    LISTVEHICS:"v1/admin/vehicle/listvehi",
    LISTBILLNO:"v1/admin/vehicle/listbillno",
    LISTDEALER:'v1/admin/vehicle/listdata',

  },
  MODEL:{
    ADDMODEL:"v1/admin/model/addmodel",
    LISTMODEL:"v1/admin/model/listmodel",
    EDITMODEL:"v1/admin/model/editmodel",
    DELETEMODEL:"v1/admin/model/deletemodel?id="
  },
  PURCHASE:{
    LISTPURCHSE:"v1/admin/purchase/listpurchase",
    ADDPURCHASE:"v1/admin/purchase/addpurchase",
    DELETEPURCHASE:"v1/admin/purchase/deletepurchase?id=",
    LISTBILLNO:"v1/admin/purchase/listbillno",
    LISTPURCHSEFILTER:"v1/admin/purchase/listpurchasefilter",
    LISTPRINT:"v1/admin/purchase/listpurchaseprint",
    LISTDEALER:'v1/admin/purchase/listdata',


  },

  PAYMENTOUT:{
    ADDPAYMENTOUT:"v1/admin/paymentout/addpaymentout",
    LISTPAYMENTOUT: "v1/admin/paymentout/listpaymentout",
    EDITPAYMENTOUT:"v1/admin/paymentout/editpaymentout",
    DELETEPAYMENTOUT:"v1/admin/paymentout/deletepaymentout?id=", 
    LISTPARTIES:"v1/admin/paymentout/listparties",
    EDITPARTIES:"v1/admin/paymentout/listParties"  },

  SALES:{
    ADDSALES:"v1/admin/sales/addsales",
    LISTSALES:"v1/admin/sales/listsales",
    DELETESALES:"v1/admin/sales/deletesales?id=",
    LISTSALESCASH:"v1/admin/sales/listsalescash",
    LISTBILLNO:"v1/admin/sales/listbillno",
    // LISTLOADREPORT:"v1/admin/sales/listloadreport",
    LISTPRINT:"v1/admin/sales/listpurchaseprint",
    LISTLOADREPORT:"v1/admin/sales/listloadreport"

  },
  PAYMENTIN:{
    ADDPAYMENTIN:"v1/admin/paymentin/addpaymentin",
    LISTPAYMENTIN: "v1/admin/paymentin/listpaymentin",
    EDITPAYMENTIN:"v1/admin/paymentin/editpaymentin",
    DELETEPAYMENTIN:"v1/admin/paymentin/deletepaymentin?id=", 
    LISTPARTIES:"v1/admin/paymentout/listparties",
  },
  TAX:
  {
    ADDTAX:"v1/admin/tax/addtax",
    LISTTAX:"v1/admin/tax/listtax",
    EDITTAX:"v1/admin/tax/edittax",
    DELETETAX:"v1/admin/tax/deletetax?id=",
  },
  ACCOUNT:{
    LISTACCOUNT:"v1/admin/account/listaccount",
    ADDACCOUNT:"v1/admin/account/addaccount",
    EDITACCOUNT:"v1/admin/account/editaccount",
    DELETEACCOUNT:"v1/admin/account/deleteaccount?id=",
  },
  BANKACCOUNT:{
    LISTACCOUNT:"v1/admin/bankaccount/listaccount",
    ADDACCOUNT:"v1/admin/bankaccount/addaccount",
    EDITACCOUNT:"v1/admin/bankaccount/editaccount",
    DELETEACCOUNT:"v1/admin/bankaccount/deleteaccount?id=",
  },
  CASHADJUSTMENT:{
    LISTCASH:"v1/admin/cashadjustment/listcashadjustment",
    ADDCASH:"v1/admin/cashadjustment/addcashadjustment",
    EDITACCOUNT:"v1/admin/bankaccount/editaccount",
    DELETEACCOUNT:"v1/admin/cashadjustment/deletecashadjustment?id=",
    LISTAMOUNT:"v1/admin/cashadjustment/listcash",
  },
  DAYBOOK:{
    LISTDAYBOOK:"v1/admin/daybook/listdaybook",
  },
    CUSTOMER:{
      ADDCUSTOMER:"v1/admin/customer/addcustomer",
      LISTCUSTOMER:"v1/admin/customer/listcustomer",
      EDITCUSTOMER:"v1/admin/customer/editcustomer",
      DELETECUSTOMER:"v1/admin/customer/deletecustomer?id=",
},
BRAND:{
  ADDBRAND:"v1/admin/brand/addbrand",
  EDITBRAND:"v1/admin/brand/editbrand",
  LISTBRAND:"v1/admin/brand/listbrand",
  DELETEBRAND:"v1/admin/brand/deletebrand?id=",
},
 VEHICELPURCHASE:{
  LISTPURCHSE:"v1/admin/vehiclepurchase/listpurchase",
  ADDPURCHASE:"v1/admin/vehiclepurchase/addpurchase",
  DELETEPURCHASE:"v1/admin/vehiclepurchase/deletepurchase?id=",
  LISTBILLNO:"v1/admin/vehiclepurchase/listbillno",
  LISTPURCHSEFILTER:"v1/admin/vehiclepurchase/listpurchasefilter",
  LISTPURCHASEVEHICEL:"v1/admin/vehiclepurchase/listpurchase",
  LISTPRINT:"v1/admin/vehiclepurchase/listpurchaseprint",
  LIST:"v1/admin/vehiclepurchase/list",
 
},
DEALERPAGE:{
ADDDEALER:"v1/admin/dealerpage/adddealer",
LISTDEALERS:"v1/admin/dealerpage/listdealer",
EDITDEALERS:"v1/admin/dealerpage/editdealer",
DELETEDEALERS:"v1/admin/dealerpage/deletedealer?id=",
},
COLOR:{
ADDCOLOR:"v1/admin/color/addcolor",
LISTCOLOR:"v1/admin/color/listcolor",
EDITCOLOR:"v1/admin/color/editcolor",
DELETECOLOR:"v1/admin/color/deletecolor?id=",
}

};
